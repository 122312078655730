import React from 'react';
import './Homecard-style.css';

function Homecard() {
  return (
    <div className='banner'>
      <img src='../assets/Banner.jpg' alt='web-banner'/>
    </div>
  )
}

export default Homecard
